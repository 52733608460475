<template>
  <main>
    <Header title="Works" />
    <teleport to="head">
      <meta name="title" content="Works - Hasolu" />
      <meta
        name="description"
        content="
Projects and more"
      />
      <meta name="robots" content="index, follow" />

      <meta name="language" content="English" />
      <meta name="author" content="hasolu" />
    </teleport>
    <div
      class="container mx-auto h-[calc(100vh-9rem)] pb-14  flex items-center justify-center"
    >
      <transition
        enter-active-class="animate__animated animate__fadeInDown   "
        leave-active-class="animate__animated animate__fadeOutDown "
        mode="out-in"
      >
        <h1
          v-if="show"
          class="text-2xl text-black dark:text-white text-center sm:text-7xl font-hslBold"
        >
          This page is being prepared
        </h1>
      </transition>
    </div>
  </main>
</template>
<script>
import Header from "@/components/Header";
export default {
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  components: { Header },
};
</script>
